/* Typography */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-simple-link {
  text-decoration: underline;
  transition: color 350ms;
}

.RS-simple-link:--enter {
  color: var(--RS-primary-button-color);
}

.RS-link {
  color: inherit;
  text-decoration: none;
  padding-bottom: var(--RS-space-2);
  position: relative;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  left: 0;
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-light-grey-color);
  position: absolute;
  transform: scaleX(0);
}

.RS-link:--enter:before {
  transform-origin: 0;
  transform: scaleX(1);
}

.RS-link--active:before,
.RS-link--active:--enter:before{
  @media (--md-viewport) {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap{
  white-space: nowrap;
}

.RS-br-xs{
  display: initial;

  @media (--sm-viewport) {
    display: none;
  }
}


.RS-br-sm{
  display: initial;

  @media (--md-viewport) {
    display: none;
  }
}


.RS-br-md{

  @media (--sm-viewport) {
    display: none;
  }

  @media (--lg-viewport) {
    display: initial;
  }
}

.RS-br-lg{
  display: none;

  @media (--lg-viewport) {
    display: initial;
  }
}

.RS-inline-icon{
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
}

.RS-inline-icon-external-link{
  font-size: 90%;
  line-height: 90%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
}

.RS-inline-icon-external-link svg path{
  fill: var(--RS-primary-button-color);
}

.RS-job-listing .RS-inline-icon-external-link{
  transform: rotateZ(-45deg);
  @media (--md-viewport) {
    transform: none;
  }
}

.RS-job-listing:--enter .RS-inline-icon-external-link{
  transform: rotateZ(-45deg);
}

.Typewriter__cursor{
  transform: scaleY(.9) scaleX(.8);
  display: inline-flex;
  transform-origin: bottom;
}

.RS-font-weight-bold{
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-regular{
  font-weight: var(--RS-font-weight-variable-regular);
}


.RS-font-style-italic{
  display: inline-flex;
  transform: skewX(-8deg);
}