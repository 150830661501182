/* Global */

html {
  font-size: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Satoshi-Variable", system-ui, sans-serif;
  font-size: 16px;
  font-weight: var(--RS-font-weight-variable-regular);
  line-height: 1;
  color: var(--RS-light-grey-color);
  letter-spacing: 0;
  background-color: var(--RS-space-black-color);
  transition: none;
  font-feature-settings: "ss01";
}

body > main {
  width: 100%;
  min-height: 1px;
  overflow: hidden;
}

a{
  -webkit-tap-highlight-color: transparent;
  outline: none;
}


@media screen and (min--moz-device-pixel-ratio:0) {

  /* Visually neglectible rotation added to 'modest' animations which normally result in shaking/jumping effect, FF only */
  :root{
      --RS-rotate-firefox-fix: 0.03deg;
  }

  /* Invisible outline added to prevent skewed/rotated edges to be pixelated/un-antialiased */
  svg,
  path{
     outline: 1px solid rgba(255,255,255,0);
  }

  html{
      scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-light-grey-color);
    background: var(--RS-the-red-color);
  }
}

::selection {
  color: var(--RS-light-grey-color);
  background: var(--RS-the-red-color);
}

body::-webkit-scrollbar{
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-space-black-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-light-grey-color);
  border-radius: 4px;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}

.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

[role="button"] {
  cursor: pointer;
}

:focus{
  outline: unset;
}

svg path,
svg g{
  transform-box: fill-box;
  transform-origin: center;
}

img,
video{
  width: 100%;
  height: auto;
  outline: 1px solid transparent;
}

ul{
  list-style: none;
}

.RS-typeform{
  font-family: "Satoshi-Variable", system-ui, sans-serif;
}

.RS-contact-form-wrapper{
  min-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4vh auto;

  @media (--lg-viewport) {
    min-width: 33%;
    margin: 6vh auto 0 auto;
  }

}

