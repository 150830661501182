/* Top Bar */

.RS-top-bar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  @media (--md-viewport) {
    padding: var(--RS-space-24) var(--RS-space-32);
   
  }
}

.RS-top-bar:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(16, 15, 15,.89) 0%, rgba(16, 15, 15,.34) 67%, rgba(16, 15, 15,0) 100%);
  z-index: -1;
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: var(--RS-col-8);
  height: 32px;
  margin: 0 auto;
}

.RS-top-bar__brand{
  z-index: 1;
}

.RS-top-bar__logo {
  width: 108px;
  height: 34px;
  margin: 0 var(--RS-space-8) 0 0;
}

.RS-top-bar__nav {
    height: 100vh;
    width: 100vw;
    background: var(--RS-the-red-color);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: none;
    position: fixed;
    top: var(--RS-space-24-n);
    left: var(--RS-space-24-n);
    gap: var(--RS-space-32);
    transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
    transform: translateX(100%);
    /* font-size: var(--RS-font-size-mobile-nav); */
    font-size: var(--RS-font-size-h1-intro);
    padding: 10vh var(--RS-space-24) var(--RS-space-24) var(--RS-space-24);

  @media (--md-viewport) {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    height: inherit;
    gap: calc(var(--RS-space-24) - var(--RS-space-4));
    width: unset;
    font-size: inherit;
    background: unset;
    position: relative;
    top: unset;
    left: unset;
    margin-left: auto;
    transform: none;
    padding: unset;
    align-items: center;
    font-size: var(--RS-font-size-top-nav);
  }

  @media (--lg-viewport) {
    gap: calc(var(--RS-space-32) + var(--RS-space-4));
  }
}

.RS-top-bar__nav--displayed{
  display: flex;
}

.RS-top-bar__nav--opened{
  transform: translateX(0%);
}

.RS-top-bar__nav > .RS-top-bar__elem > .RS-link{
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  font-weight: var(--RS-font-weight-variable-regular);
  letter-spacing: .25px;
  display: block;
  /* text-align: center; */
  

  @media (--md-viewport) {
    opacity: 1;
    transform: none;
    font-weight: var(--RS-font-weight-variable-bold);
    display: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem > .RS-link--visible{
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(1) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*1);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(2) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*2);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(3) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*3);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(4) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*4);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(5) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*5);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(6) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*6);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(7) > .RS-link{
  transition-delay: calc(var(--RS-anim-delay)*7);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__mobile-nav-icon {
  display: block;
  margin-left: auto;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  
  @media (--md-viewport) {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span{
  width: calc(var(--RS-space-32) + var(--RS-space-1));
  height: var(--RS-space-2);
  background-color: #ecf0f1;
  display: block;
  margin: var(--RS-space-6) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(1){
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-6) + 0px));
}


.RS-top-bar__mobile-nav-icon span:nth-of-type(2){
  transform: translateX(var(--RS-space-6-n));
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2){
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-6-n) - 0px));
}

.hamburger:--enter{
  cursor: pointer;
}

.RS-top-bar__job-promo-caption {
  display: inline-block;
  margin: 0 var(--RS-space-16) 0 0;
}

@keyframes showTopBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.RS-top-bar__lang-switch{
  display: flex;
  flex-direction: row;
  margin-left: var(--RS-space-16);
  padding-left: 0;
}

.RS-top-bar__lang-switch > .RS-button{
  min-width: 86px;
  height: 42px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--RS-font-weight-variable-bold);
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  color: inherit;
}

.RS-top-bar__lang-switch > .RS-button:--enter{
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  background-color: unset;
}

.RS-top-bar__lang-switch > .RS-button > span{
  font-size: var(--RS-font-size-top-nav);
}

.RS-top-bar__lang-switch > .RS-button.RS-button--lang-active{
  background-color: var(--RS-mid-grey-color);
}

.RS-top-bar__lang-switch > .RS-button:first-of-type{
  border-radius: var(--RS-space-6) 0 0 var(--RS-space-6);
}

.RS-top-bar__lang-switch > .RS-button:last-of-type{
  border-radius: 0 var(--RS-space-6) var(--RS-space-6) 0;
  margin-left: var(--RS-space-2-n);
}

.RS-top-bar__lang-switch > .RS-button:before{
  width: 50%;
  left: 25%;
  bottom: var(--RS-space-2);
}

.RS-top-bar__lang-switch > .RS-button:first-of-type:after{
  content: 'PL';

  @media (--md-viewport) {
    content: 'Polski';
  }
}

.RS-top-bar__lang-switch > .RS-button:last-of-type:after{
  content: 'EN'; 

  @media (--md-viewport) {
    content: 'English';
  }
}

.RS-top-bar__elem{
  position: relative;
}

.RS-top-bar__dropdown{
  display: flex;
  flex-direction: column;
  gap: calc(var(--RS-space-16) + var(--RS-space-4));
  font-size: var(--RS-font-size-p);
  align-items: flex-start;
  margin-top: var(--RS-space-24);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 0 var(--RS-space-16);
  text-wrap: nowrap;

  @media (--md-viewport) {
  position: absolute;
  top: calc(100% + var(--RS-space-16));
  /* padding: var(--RS-space-24) var(--RS-space-64) var(--RS-space-24) var(--RS-space-24); */
  padding: var(--RS-space-24);
  display: none;
  /* display: flex; */
  font-size: inherit;
  margin-top: 0;
  font-weight: var(--RS-font-weight-variable-regular);
  }
}

.RS-top-bar__dropdown--displayed{
  display: flex;
}

.RS-top-bar__dropdown:before{

  @media (--md-viewport) {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -10%;
    width: 120%;
    height: 100%;
    border-radius: var(--RS-space-8);
    background-color: var(--RS-space-grey-color);
    border: var(--RS-space-1) solid var(--RS-light-grey-color);
    border-radius: var(--RS-space-16);
    box-shadow: calc(var(--RS-space-1) + var(--RS-space-2)) calc(var(--RS-space-1) + var(--RS-space-2)) var(--RS-light-grey-color);
    transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
    opacity: 0;
    transform: translateY(var(--RS-space-8-n)) scaleY(.67);
    transform-origin: top;
    /* opacity: 1;
    transform: none; */
  }
}

.RS-top-bar__dropdown--visible:before{

  @media (--md-viewport) {
    opacity: 1;
    transform: none;
  }
}

.RS-top-bar__dropdown:after{

  @media (--md-viewport) {
    content: '';
    display: block;
    position: absolute;
    left: 0%;
    width: 70%;
    height: var(--RS-space-16);
    top: var(--RS-space-16-n);
    opacity: 0;
  }
}

.RS-top-bar__dropdown > .RS-link{
  opacity: 0;
  transform: translateX(var(--RS-space-4-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  /* opacity: 1;
  transform: none; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
  gap: var(--RS-space-16);

  @media (--md-viewport) {

    gap: var(--RS-space-32);
    flex-direction: row;
    padding-bottom: var(--RS-space-4);
    /* margin-right: auto; */
    width: 100%;
  }
}

.RS-top-bar__dropdown--visible > .RS-link,
.RS-top-bar__dropdown > .RS-link--visible{

    opacity: 1;
    transform: translateX(0);

}

.RS-top-bar__dropdown  > .RS-link:nth-of-type(1){
  transition-delay: calc(var(--RS-anim-delay)*3.2);
  @media (--md-viewport) {
    transition-delay: calc(var(--RS-anim-duration-md)/3 + var(--RS-anim-delay)*0);
  }
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(2){
  transition-delay: calc(var(--RS-anim-delay)*3.4);
  @media (--md-viewport) {
    transition-delay: calc(var(--RS-anim-duration-md)/3 + var(--RS-anim-delay)*1);
  }
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(3){
  transition-delay: calc(var(--RS-anim-delay)*3.6);
  @media (--md-viewport) {
    transition-delay: calc(var(--RS-anim-duration-md)/3 + var(--RS-anim-delay)*2);
  }
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(4){
  transition-delay: calc(var(--RS-anim-delay)*3.8);
  @media (--md-viewport) {
    transition-delay: calc(var(--RS-anim-duration-md)/3 + var(--RS-anim-delay)*3);
  }
}

.RS-top-bar__dropdown > .RS-link > .RS-inline-icon > svg{
  width: calc(var(--RS-space-24) - var(--RS-space-4));
  height: calc(var(--RS-space-24) - var(--RS-space-4));
}

.RS-top-bar__dropdown > .RS-link:before{
  display: none;
}

.RS-top-bar__dropdown > .RS-link > span:first-of-type{
  position: relative;
  padding-bottom: var(--RS-space-4);
}

.RS-top-bar__dropdown > .RS-link > span:first-of-type:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  left: 0;
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-light-grey-color);
  position: absolute;
  transform: scaleX(0);
}

.RS-top-bar__dropdown > .RS-link > span:first-of-type:--enter:before {
  transform-origin: 0;
  transform: scaleX(1);
}