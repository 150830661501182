.RS-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--RS-primary-button-color);
  border-radius: 8px;
  box-shadow: inset 0 0 0 2px var(--RS-primary-button-color);
  padding: 0 var(--RS-space-16);
  height: 48px;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  cursor: pointer;

  @media (--md-viewport) {
    width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32));
    padding: var(--RS-space-12) var(--RS-space-16);
  }
}

.RS-button.RS-button--active,
.RS-button:--enter {
  color: var(--RS-light-grey-color);
  background-color: var(--RS-primary-button-color);
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
}

.RS-button.RS-button--filled {
  color: var(--RS-light-grey-color);
  background-color: var(--RS-the-red-color);
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
}

.RS-button.RS-button--filled:--enter {
  background-color: var(--RS-primary-button-color);
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
}

.RS-button.RS-button--enter-primary:--enter {
  background-color: var(--RS-primary-button-color);
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
}

.RS-button > span {
  position: relative;
  display: inline-block;
  line-height: var(--RS-line-height-md);
  font-size: clamp(1.125rem, 1.1123rem + 0.0633vw, 1.1875rem);
}

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  font-size: 24px;
  font-size: clamp(1.25rem, 1.1994rem + 0.2532vw, 1.5rem);
  line-height: 1;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}

.RS-button.RS-button--small {
  /* width: auto;
  max-width: none;
  font-weight: var(--RS-font-weight-variable-regular); */

  @media (--md-viewport) {
    padding: var(--RS-space-16);
    height: 42px;
    border-radius: var(--RS-space-6);
    opacity: 1;
    font-weight: var(--RS-font-weight-variable-bold);
  }
}



.RS-button.RS-button--small > span {
  /* font-size: inherit;
  line-height: inherit;
  padding-bottom: inherit; */

  @media (--md-viewport) {
    line-height: 1;
    font-size: var(--RS-font-size-top-nav);
    padding-bottom: var(--RS-space-4);
  }
}

.RS-button.RS-button--small > svg.fa-arrow-right {
  font-size: inherit;
  line-height: inherit;
  padding-bottom: inherit;
  /* transform: translate3d(0, 0, 0) rotateZ(-45deg); */

  @media (--md-viewport) {
    margin: 0 0 0 var(--RS-space-8);
    padding-bottom: var(--RS-space-2);
    /* transform: inherit; */
  }
}

.RS-button--external > svg.fa-arrow-right{
  transform: translate3d(0, 0, 0) rotateZ(-45deg);
  @media (--md-viewport) {
    transform: translate3d(0, 0, 0);
  }
}

.RS-button--external:--enter > svg.fa-arrow-right {
  transform: translate3d(0, 0, 0) rotateZ(-45deg);
}

.RS-button--internal > svg.fa-arrow-right{
  transform: translate3d(0, var(--RS-space-4), 0) rotateZ(45deg);
  @media (--md-viewport) {
    transform: translate3d(0, 0, 0) rotateZ(45deg);
  }
}

.RS-button--internal:--enter > svg.fa-arrow-right {
  transform: translate3d(0, var(--RS-space-4), 0) rotateZ(90deg);
  @media (--md-viewport) {
    transform: translate3d(0, 0, 0) rotateZ(90deg);
  }
}

.RS-button--centered{
  margin: 0 auto;
}

.RS-intro .RS-button + .RS-button{
  margin-top: var(--RS-space-16);



  @media (--sm-viewport) {
    margin-left: var(--RS-space-16);
  }

  @media (--lg-viewport) {
    margin-left: var(--RS-space-16);
  }

  @media (--xlg-viewport) {
    margin-left: var(--RS-space-32);
  }
}

.RS-button.RS-button-link:--enter{
  background-color: var(--RS-the-red-color);
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
}

.RS-button.RS-button--large {
  height: auto;
  padding: var(--RS-space-12) var(--RS-space-32);
}


.RS-button .RS-display--md{
  display: inherit;
  @media (--lg-viewport) {
    display: none;
  }
}
  
.RS-button .RS-display--lg{
  display: none;
  @media (--lg-viewport) {
    display: inherit;
  }
}

/* .RS-button.RS-button--wosp{
  padding: var(--RS-space-16);
  padding-right: calc(var(--RS-space-32) + var(--RS-space-6));
  margin-left: var(--RS-space-8);
  height: 42px;
  min-width: unset;
  background: unset;
  box-shadow: inset 0 0 0 2px var(--RS-the-red-color);
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-button.RS-button--wosp:--enter{
  background: unset;
  box-shadow: inset 0 0 0 2px var(--RS-primary-button-color);
}

.RS-button.RS-button--wosp span{
  padding-bottom: var(--RS-space-4);
}

.RS-button.RS-button--wosp svg{
  width: calc(var(--RS-space-64) - var(--RS-space-8));
  margin-left: var(--RS-space-8);
  margin-bottom: var(--RS-space-2);
  position: absolute;
  right: var(--RS-space-24-n);
  transition: transform var(--RS-anim-duration-xs) var(--RS-default-transition);
  pointer-events: none;
} */


.RS-top-bar__nav .RS-button > svg{
  display: none;
  @media (--md-viewport) {
    display: inline-block;
  }
}


.RS-top-bar .RS-button.RS-button--filled{
  background-color: var(--RS-primary-button-color);
  @media (--md-viewport) {
      background-color: var(--RS-the-red-color);
    }
}